import { useEffect, useState } from "react";
import { styled } from "styled-components";
import { API_BASE, TOASTPROPS } from "../shared/Constants";
import { useAuth0 } from "@auth0/auth0-react";
import { ModelList } from "../shared/Interfaces";
import { parseDate } from "../shared/Utils";
import { toast, ToastContainer } from "react-toastify";
import { Skeleton } from "@mui/material";

function Models() {
    const [loading, setLoading] = useState<Boolean>(true);
    const [models, setModels] = useState<ModelList>({});
    const {getAccessTokenSilently} = useAuth0();

    const parsePrice = (price: number) => {
        let parsedPrice = (price * 1e6).toFixed(2);
        parsedPrice = parsedPrice.replace(".", ",");
        return parsedPrice;
    }

    const parseTokenRateLimit = (rate: number) => {
        if (rate >= 1000000) {
            const millionRate = rate / 1000000;
            const formattedRate = millionRate % 1 === 0 ? millionRate.toFixed(0) : millionRate.toFixed(1).replace(".", ",");
            return `${formattedRate} ${millionRate > 1 ? 'milhões' : 'milhão'}`;
        } else if (rate >= 1000) {
            return `${(rate / 1000).toFixed(0)} mil`;
        } else {
            return rate.toString();
        }
    };

    useEffect(() => {
        const getModels = async () => {
            try {
                setLoading(true);
                const header = new Headers();
                const token = await getAccessTokenSilently();
                header.append("Authorization", `Auth ${token}`);
                const response = await fetch(`${API_BASE}/api/chat/models`, {
                    method: "GET",
                    headers: header
                });
                if (!response.ok) {
                    throw new Error("Failed to fetch models");
                }
                const data = await response.json();
                setModels(data["models"]);
                setLoading(false);
            } catch (error) {
                toast.error("Erro ao recuperar modelos disponíveis.", TOASTPROPS);
            }
        };
        getModels();
    }, []);

    return (
        <Container>
            <TableContainer>
            <Table>
                <thead>
                    <tr>
                        <th style={{borderBottom: 'none'}}></th>
                        <th style={{borderBottom: 'none'}}></th>
                        <th colSpan={3} style={{borderBottom: 'none'}}>Limites de Uso</th>
                        <th colSpan={2} style={{borderBottom: 'none'}}>Custo por Milhão de Tokens</th>
                        <th style={{borderBottom: 'none'}}></th>
                    </tr>
                    <tr>
                        <th>Modelo</th>
                        <th>Contexto Máximo</th>
                        <th>Entrada [TPM]</th>
                        <th>Saída [TPM]</th>
                        <th>Requisições [RPM]</th>
                        <th>Entrada</th>
                        <th>Saída</th>
                        <th>Data de Lançamento</th>
                    </tr>
                </thead>
                <tbody>
                    {loading && 
                        <>
                            {Array.from(new Array(4)).map((_, index) => (
                                <tr key={index}>
                                    {Array.from(new Array(8)).map((_, index) => (
                                        <td key={index}>
                                            <Skeleton variant="text" width={100} />
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </>
                    }
                    {!loading && Object.keys(models).map((key) => {
                        const model = models[key];
                        return (
                            <tr key={key}>
                                <td>{key}</td>
                                <td>{model.max_tokens} tokens</td>
                                <td>{parseTokenRateLimit(model.token_rate_limit_input)}</td>
                                <td>{parseTokenRateLimit(model.token_rate_limit_output)}</td>
                                <td>{model.requests_per_minute}</td>
                                <td>R$ {parsePrice(model.input_price)}</td>
                                <td>R$ {parsePrice(model.output_price)}</td>
                                <td>{parseDate(model.update_date)}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            </TableContainer>
            <Text>TPM = Tokens por minuto. RPM = Requisições por minuto.</Text>
            <Warning>O modelo <strong>sabia-2-medium</strong> será descontinuado em 30 de setembro de 2024. Recomendamos a migração para o modelo <strong>sabia-3</strong>, que possui um desempenho superior com mesmo preço médio por token.</Warning>
            <ToastContainer />
        </Container>
    );
}

export default Models;

const Warning = styled.p`
    font-size: 1.1rem;
    color: ${props => props.theme.red};
    font-family: Plus Jakarta Sans, sans-serif;
    line-height: 1.7rem;
    margin-top: 0px;
    margin-bottom: 20px;
`;

const TableContainer = styled.div`
    overflow-x: auto;
    min-height: 150px;
    width: 100%;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.white_background};
`;

const Text = styled.p`
    font-size: 1.1rem;
    font-weight: 350; 
    color: ${props => props.theme.dark_blue};
    font-type: regular;
    font-family: Plus Jakarta Sans, sans-serif;
    // set space between lines
    line-height: 1.7rem;

    @media (max-width: 768px) {
        padding-right: 15px;
    }
`

const Table = styled.table`
    max-width: 100%;

    th, td {
        text-align: left;
        padding-top: 15px;
    }

    th {
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }

    th:nth-child(1), td:nth-child(1) {
        min-width: 250px; // Example width for first column
        padding-right: 20px;
    }

    th:not(:first-child), td:not(:first-child) {
        min-width: 130px; // Example width for other columns
        padding-right: 20px;
    }

    th:last-child, td:last-child {
        padding-right: 5px;
    }

    @media (max-width: 768px) {
        th:nth-child(1), td:nth-child(1) {
            min-width: 0px;
            padding-right: 20px;
        }
    
        th:not(:first-child), td:not(:first-child) {
            min-width: 0px;
        }
    
        th:last-child, td:last-child {
            min-width: 0px;
            padding-right: 20px;
        }
    }

    font-size: 1.1rem;
    font-weight: 400;
    color: ${props => props.theme.dark_blue};
    font-family: Plus Jakarta Sans, sans-serif;
`;

export const API_BASE = process.env.REACT_APP_NODE_ENV !== 'development' ? "https://chat.maritaca.ai" : process.env.REACT_APP_API_BASE_URL;

export const MONTHS_MAP: { [key: number]: string } = {
    1: "Janeiro",
    2: "Fevereiro",
    3: "Março",
    4: "Abril",
    5: "Maio",
    6: "Junho",
    7: "Julho",
    8: "Agosto",
    9: "Setembro",
    10: "Outubro",
    11: "Novembro",
    12: "Dezembro"
};

export const TOASTPROPS: { [key: string]: any } = {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
}